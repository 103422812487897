import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import PageWrapper from '../components/PageWrapper'
import SEO from '../components/SEO/SEO'

import { PopupText } from 'react-calendly'

export const ServicesPageTemplate = ({ title, content, onlineBooking, contentComponent }) => {

	const PageContent = contentComponent || Content
	let calendlyBaseUrl = 'https://calendly.com/stetindadvokat/'

	return (
		<PageWrapper title={title}>
			{onlineBooking && (
				<div
					className="calendly-link-services card-link"
					style={{
						width: '250px',
						padding: '12px',
						marginBottom: '24px',
						textAlign: 'center',
						border: '1px solid black',
					}}
				>
					<PopupText
						text="Book konsultasjon online"
						url={calendlyBaseUrl}
						styles={{
							color: '#000',
							display: 'block',
							width: '100%',
							height: '100%',
						}}
					/>
				</div>
			)}
			<PageContent className="content" content={content} />
		</PageWrapper>
	)
}

ServicesPageTemplate.propTypes = {
	title: PropTypes.string.isRequired,
  onlineBooking: PropTypes.bool,
	content: PropTypes.string,
	contentComponent: PropTypes.func,
}

const ServicesPage = ({ data }) => {
	const { markdownRemark: post } = data

	return (
		<Layout color="black">
			<SEO title={post.frontmatter.title} />
			<ServicesPageTemplate contentComponent={HTMLContent} title={post.frontmatter.title} content={post.html} onlineBooking={post.frontmatter.onlineBooking} />
		</Layout>
	)
}

ServicesPage.propTypes = {
	data: PropTypes.object.isRequired,
}

export default ServicesPage

export const servicesPageQuery = graphql`
	query ServicesPage($id: String!) {
		markdownRemark(id: { eq: $id }) {
			html
			frontmatter {
				title
        onlineBooking
			}
		}
	}
`
